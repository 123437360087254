/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import { Container, Inner } from './Elements';
import { Link } from 'gatsby';
import { font } from '../consts/style';
import mq from '../style/mq';

const BGWrapper = styled(BackgroundImage)`
  width: 50%;
  ${({ right }) => right && 'background-position: center right;'}
  ${mq.tabletSmall`
    width: 100%;
  `}
`;

const Wrapper = styled.div`
  position: relative;
  height: 39.5rem;

  ${Inner} {
    position: relative;
    z-index: 5;
    display: flex;
    height: 100%;
    padding-top: 8%;
    color: white;
    justify-content: center;
    .content {
      width: 34rem;
      max-width: 100%;
      img {
        margin-bottom: 1.2rem;
      }
      h2 {
        text-transform: uppercase;
      }

      p {
        margin-bottom: 2rem;
      }
      a {
        ${font.buttonCta};
        font-weight: bold;
      }
    }
  }
`;

export default function BlockProduct(props) {
  return (
    <Fragment>
      <BGWrapper
        fluid={props.backgroundImage.fluid}
        right={props.productSlug === '/plug'}
      >
        <Wrapper>
          <Container>
            <Inner>
              <div className="content">
                <img src={props.icon.url} alt="Icon" />
                <h2>{props.title}</h2>
                <p>{props.text}</p>
                <Link to={props.productSlug}>
                  <span>LEARN MORE</span>
                </Link>
              </div>
            </Inner>
          </Container>
        </Wrapper>
      </BGWrapper>
    </Fragment>
  );
}
