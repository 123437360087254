import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import { Container, InnerSmall } from './Elements';
import { colors, font } from '../consts/style';
import * as endpoints from '../consts/endpoints';
import mq from '../style/mq';

const Wrapper = styled.div`
  background: ${colors.darkBlue};
  height: 32.5rem;

  ${mq.tabletSmall`
      height: 40rem;
    `}

  ${InnerSmall} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;

    h3 {
      ${font.h3};
      text-transform: uppercase;
    }

    form {
      width: 100%;
      display: flex;
      margin-top: 2.4rem;
      opacity: 1;
      transition: opacity 0.4s ease;
      input {
        width: 100%;
        flex-shrink: 1;
        background: transparent;
        border: none;
        border-bottom: 0.2rem solid white;
        margin-right: 2rem;
        font-size: 2rem;
        color: white;
        border-radius: 0;
        ::-webkit-input-placeholder {
          color: white;
          font-weight: bold;
        }
      }
      button {
        width: 21rem;
        flex-shrink: 0;
        border-radius: 20rem;
        background: transparent;
        border: 0.2rem solid white;
        color: white;
        padding: 0.8rem 0 0.6rem 0;
        font-size: 2rem;
      }

      ${mq.tabletSmall`
        flex-wrap: wrap;
        justify-content: flex-end;
        input {
            margin-bottom: 4rem;
            margin-right: 0;
        }
      `}
    }

    ${props =>
      props.loading &&
      `
        pointer-events: none;
        form {
            opacity: .5;
        }
    `}
  }
`;

export default function BlockNewsletter() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  function handleSubmit(e) {
    if (e) e.preventDefault();
    if (loading) return;

    const body = JSON.stringify({ Name: name, Email: email });

    setLoading(true);

    fetch(endpoints.SUBSCRIBE, {
      method: 'post',
      body,
    })
      .then(res => res.json())
      .then(() => {
        setEmail('');
        setName('');
        setLoading(false);
        setSuccess(true);

        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      });
  }
  return (
    <Fragment>
      <Wrapper loading={loading}>
        <Container>
          <InnerSmall>
            <h3>
              {success
                ? 'Thanks!'
                : 'See the future before it happens. Sign up for notifications.'}
            </h3>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Name"
                required
              />
              <input
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <button type="submit">
                <span>Get Started</span>
              </button>
            </form>
          </InnerSmall>
        </Container>
      </Wrapper>
    </Fragment>
  );
}
