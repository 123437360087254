import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import map from 'lodash/map';
import styled from 'styled-components';

import SEO from '../components/SEO';
import Hero from '../components/Hero';
import useWindow from '../hooks/useWindow';
import BlockSimple from '../components/BlockSimple';
import BlockFeature from '../components/BlockFeature';
import BlockProduct from '../components/BlockProduct';
import BlockNewsletter from '../components/BlockNewsletter';
import BlockBlogPosts from '../components/BlockBlogPosts';

const BlockWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const indexQuery = graphql`
  {
    datoCmsHome {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroBackground {
        fluid(maxWidth: 2000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      heroMobileBackground {
        fluid(maxWidth: 1200, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      heroTextImage {
        url
      }
      blogPosts {
        id
        title
        slug
        headline
        featuredImage {
          fluid(maxWidth: 1200, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
      infoSections {
        ... on DatoCmsProductBlock {
          id
          backgroundImage {
            fluid(
              maxWidth: 1200
              imgixParams: { fm: "jpg", auto: "compress" }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          icon {
            url
          }
          text
          title
          productSlug
          internal {
            type
          }
        }
        ... on DatoCmsSimpleBlock {
          id
          text
          image {
            url
          }
          internal {
            type
          }
        }
        ... on DatoCmsFeatureBlock {
          id
          image {
            fluid(
              maxWidth: 1200
              imgixParams: { fm: "jpg", auto: "compress" }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          text
          title
          color {
            hex
          }
          internal {
            type
          }
        }
      }
    }
  }
`;

export default function Index() {
  const data = useStaticQuery(indexQuery);
  const { isSmall } = useWindow();

  const {
    seoMetaTags,
    heroBackground,
    heroMobileBackground,
    heroTextImage,
    infoSections,
    blogPosts,
  } = data.datoCmsHome;

  const heroProps = {
    background: heroBackground.fluid,
    backgroundMobile: heroMobileBackground.fluid,
    textImage: heroTextImage.url,
    isMobile: isSmall,
    pathname: '/',
  };

  const blockMap = {
    DatoCmsSimpleBlock: BlockSimple,
    DatoCmsFeatureBlock: BlockFeature,
    DatoCmsProductBlock: BlockProduct,
  };

  const blogProps = {
    blogPosts,
  };

  function renderBlocks() {
    return map(infoSections, (is, i) => {
      const BlockComponent = blockMap[is.internal.type];
      return <BlockComponent flip={(i + 1) % 2 !== 0} key={is.id} {...is} />;
    });
  }

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <Hero {...heroProps} />
      <div id="intro" />
      <BlockWrapper>{renderBlocks()}</BlockWrapper>
      <BlockNewsletter />
      <BlockBlogPosts {...blogProps} />
    </Fragment>
  );
}
