/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { font } from '../consts/style';
import mq from '../style/mq';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background: ${props => props.background};
  .image {
    height: 37.4rem;
    position: relative;
    width: 58%;
  }
  .text {
    width: 42%;
    padding: 2.4rem;
    color: white;
    display: flex;
    align-items: center;
    .inner {
      width: 34rem;
      max-width: 100%;
      h3 {
        ${font.h3};
        text-transform: uppercase;
      }
      p {
        ${font.p};
      }
    }
  }

  ${props =>
    props.flip &&
    `
    .image {
      order: 1;
    }

    .text {
      justify-content: flex-end;
    }
  `}

  ${mq.tabletSmall`
  flex-wrap: wrap;
    .image, .text {
      justify-content: flex-start;
      width: 100%;
      order: 0;
    }
  `}
`;

const BackgroundImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  height: 100%;
  z-index: 0;
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
`;

export default function BlockFeature({ flip, text, title, image, color }) {
  return (
    <Fragment>
      <Wrapper background={color.hex} flip={flip}>
        <div className="image">
          <BackgroundImg fluid={image.fluid} backgroundColor={color.hex} />
        </div>
        <div className="text">
          <div className="inner">
            <h3>{title}</h3>
            <p>{text}</p>
          </div>
        </div>
      </Wrapper>
    </Fragment>
  );
}
