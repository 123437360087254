/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { Container, InnerSmall } from './Elements';
import { colors, font } from '../consts/style';
import { Link } from 'gatsby';
import mq from '../style/mq';

const Wrapper = styled.div`
  padding: 8rem 0;
  h2 {
    font-family: 'Oceania', sans-serif;
    font-size: 5rem;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
  .posts {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;

    /* &:after {
      content: '';
      width: 0.2rem;
      height: 100%;
      top: 0;
      right: 50%;
      transform: translateX(-50%);
      background: ${colors.darkBlue};
      position: absolute;

      ${mq.tabletSmall`
        width: 100%;
        height: .2rem;
        top: 50%;
        right: 0;
        transform: translateY(-50%) translateY(0%);
      `}
    } */
    .post {
      width: 45%;

      ${mq.tabletSmall`
        width: 100%;
        margin-bottom: 10rem;

        &:last-child {
            margin-bottom: 0;
        }
      `}

      .image-wrap {
        height: 26rem;
        width: 100%;
        position: relative;
        margin-bottom: 2rem;
      }
      h3 {
        ${font.h3};
        font-family: 'Oceania', sans-serif;
        font-weight: normal;
      }
      p {
        ${font.p};
        margin-bottom: 2rem;
      }
      a {
        button {
          ${font.buttonCta};
          background: ${colors.darkBlue};
          span {
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }
    }
  }
`;

const FeatureImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  height: 100%;
  z-index: 0;
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }
`;

export default function BlockBlogPosts({ blogPosts }) {
  return (
    <Fragment>
      <Wrapper>
        <Container>
          <InnerSmall>
            <h2>Latest News</h2>
            <div className="posts">
              {blogPosts.map(bp => (
                <div className="post" key={bp.id}>
                  <div className="image-wrap">
                    <FeatureImg fluid={bp.featuredImage.fluid} />
                  </div>
                  <h3>{bp.title}</h3>
                  <p>{bp.headline}</p>
                  <Link to={`/post/${bp.slug}`} title={bp.title}>
                    <button>
                      <span>Learn More</span>
                    </button>
                  </Link>
                </div>
              ))}
            </div>
          </InnerSmall>
        </Container>
      </Wrapper>
    </Fragment>
  );
}
