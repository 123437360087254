/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { colors } from '../consts/style';
import { Container, Inner } from './Elements';
import mq from '../style/mq';

const Wrapper = styled.div`
  background: ${colors.darkBlue};
  width: 100%;
  padding: 8rem 0;

  ${Inner} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .image {
    width: 50%;
    padding-right: 2rem;
    img {
      width: 100%;
    }
  }
  .text {
    width: 50%;
    color: white;
    p {
      margin: 0;
      font-size: 2.4rem;
    }
  }

  ${mq.tabletSmall`
    .image, .text {
      width: 100%;
      p {
        font-size: 1.6rem;
      }
    }

    .image {
      margin-bottom: 2.4rem;
    }
  `}
`;

export default function BlockSimple(props) {
  return (
    <Fragment>
      <Wrapper>
        <Container>
          <Inner>
            <div className="image">
              <img src={props.image.url} alt="Icon" />
            </div>
            <div className="text">
              <p>{props.text}</p>
            </div>
          </Inner>
        </Container>
      </Wrapper>
    </Fragment>
  );
}
